import { Box, Stack, StackProps, Text } from '@qcx/ui';
import { PostCard, PostCardProps } from './PostCard';
import { ReactNode } from 'react';

export interface PostSectionProps extends Omit<StackProps, 'title'> {
  posts: PostCardProps['post'][];
  title?: ReactNode;
  postCardProps?: Partial<PostCardProps>;
}

export const PostSection = ({
  title,
  posts,
  postCardProps,
  ...props
}: PostSectionProps) => (
  <Box as="section">
    {title && (
      <Box css={{ pb: '$6' }}>
        <Text
          as="h2"
          css={{ color: '$neutral500' }}
          variant="sm"
          weight="semibold"
        >
          {title}
        </Text>
      </Box>
    )}
    <Stack flow="column" gap="$8" {...props}>
      {posts.map((post, index) => (
        <PostCard
          key={post.slug}
          flow={{
            '@xs': 'column',
            '@md': 'row'
          }}
          post={post}
          priority={index < 4 ? true : false}
          {...postCardProps}
        />
      ))}
    </Stack>
  </Box>
);
