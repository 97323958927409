import { Article, SimpleArticle } from '@atreus/data';
import { Badges } from '../Badges';
import { Box, CSS, Icon, Image, Stack, Text, styled } from '@qcx/ui';
import { Card, CardProps } from '../Card';
import { ComponentProps } from 'react';
import { Link } from '@qcx/remix';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { route } from '../../route';

export interface PostCardProps {
  css?: CSS;
  flow?: StyledCardProps['flow'];
  priority?: boolean;
  elevated?: CardProps['elevated'];
  variant?: 'sm' | 'md';
  squared?: boolean;
  as?: string;
  post: Article | SimpleArticle;
}

type StyledCardProps = ComponentProps<typeof StyledCardBody>;

export const PostCard = ({
  post,
  css,
  priority,
  flow,
  squared,
  variant = 'sm',
  elevated,
  ...props
}: PostCardProps) => {
  const cardSizes: Record<string, number> = {
    sm: 162,
    md: 216
  };

  return (
    <Link
      css={{
        ...css
      }}
      title={post.title}
      to={route('news-show', {
        slug: post.slug
      })}
    >
      <Card elevated={elevated}>
        <StyledCardBody flow={flow}>
          {post.image ? (
            <StyledImage
              css={{
                w: '$full',
                h: cardSizes[variant],
                '@lg': {
                  w: squared ? cardSizes[variant] : '$full'
                }
              }}
              width={280}
              height={cardSizes[variant]}
              alt={`É uma imagem do post com o título ${post.title}`}
              loading={priority ? 'eager' : 'lazy'}
              priority={priority}
              src={post.image}
              noAspectRatio
            />
          ) : (
            <StyledImageEmpty
              css={{
                h: cardSizes[variant],
                minW: 280,
                '@lg': {
                  w: squared ? cardSizes[variant] : '$full'
                }
              }}
            >
              <Icon
                css={{ color: '$neutral700' }}
                icon={faImage}
                role="img"
                size="5xl"
                title="Imagem não disponível"
              />
            </StyledImageEmpty>
          )}
          <StyledCardBodyText
            css={{ color: '$text', flex: 1, flexCol: true }}
            flow={flow}
          >
            <Stack flow="column" gap="$3">
              {post.editorials && (
                <Stack gap="$3">
                  <Badges items={post.editorials} />
                </Stack>
              )}
              <Text
                as="h2"
                css={{
                  mb: '$4',
                  color: '$neutral900',
                  lineHeight: '$2xl',
                  fontFamily: '$heading'
                }}
                variant="lg"
                weight="semibold"
                {...props}
              >
                {post.title}
              </Text>
            </Stack>
            <Box>
              <Text
                css={{
                  width: 10,
                  color: '$neutral500',
                  maxW: '30ch',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal'
                }}
                variant="sm"
              >
                {(post as Article).subtitle}
              </Text>
            </Box>
            {(post as Article).updatedAt && (
              <>
                <Text
                  as="span"
                  css={{ color: '$textMuted', mt: 'auto', mb: '$2' }}
                  variant="sm"
                >
                  {(post as Article).author && (
                    <>
                      <Text>Por </Text>
                      <Text
                        as="span"
                        css={{ color: '$neutral800' }}
                        weight="medium"
                      >
                        {(post as Article).author} {''}
                      </Text>
                      <Text> - </Text>
                    </>
                  )}
                </Text>
                <Text as="span" css={{ color: '$neutral600' }} weight="regular">
                  Atualizada em {(post as Article).updatedAt}
                </Text>
              </>
            )}
          </StyledCardBodyText>
        </StyledCardBody>
      </Card>
    </Link>
  );
};

const StyledImage = styled(Image, {
  w: '$full',
  '@md': {
    maxW: 280
  },
  d: 'flex',
  '& img': {
    objectFit: 'cover',
    h: '$full'
  },
  rounded: '$4xl',
  '&:hover::after': {
    content: '',
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.05) 100%)',
    rounded: 'inherit',
    position: 'absolute',
    minMaxSize: '$full',
    left: 0,
    top: 0
  }
});

const StyledImageEmpty = styled(Box, {
  bg: '$neutral50',
  w: '$full',
  '@md': {
    maxW: 280
  },
  rounded: '$4xl',
  flexCenter: true,
  aspectRatio: '1 / 0'
});

const StyledCardBody = styled('div', {
  '@lg': {
    d: 'flex'
  },
  alignItems: 'stretch',
  variants: {
    flow: {
      row: {
        flexDir: 'row',
        [`& ${StyledImage}`]: {
          maxW: 280,
          '@md': { maxW: 343 },
          '@lg': { maxW: 280 },
          pb: 0,
          '& img': {
            h: '$full'
          }
        },
        minW: 280
      },
      column: {
        flexDir: 'column',
        [`& ${StyledImage}`]: {
          maxW: 'none',
          '& img': {
            position: 'absolute',
            inset: 0
          }
        }
      }
    }
  },
  defaultVariants: {
    flow: 'column'
  }
});

const StyledCardBodyText = styled('div', {
  variants: {
    flow: {
      row: {
        '@sm': {
          mt: '$9'
        },
        '@lg': {
          mt: 0,
          px: '$6'
        }
      },
      column: {
        mt: '$9',
        px: '0'
      }
    }
  },
  defaultVariants: {
    flow: 'column'
  }
});
