import { Box, styled } from '@qcx/ui';
import { ComponentProps } from 'react';

export type CardProps = ComponentProps<typeof StyledCard>;

export const Card = ({ children, ...props }: CardProps) => (
  <StyledCard {...props}>
    <Box css={{ overflow: 'hidden' }}>{children}</Box>
  </StyledCard>
);

const StyledCard = styled('div', {
  rounded: '$md',
  variants: {
    elevated: {
      true: {
        shadow: '$md',
        border: '1px solid $separatorColor',
        '& > div': {
          shadow: '$xs'
        }
      },
      false: {
        shadow: 'none',
        border: 'none',
        '& > div': {
          shadow: 'none'
        }
      }
    }
  },
  defaultVariants: {
    elevated: true
  }
});
